<template lang="pug">
include ../../../configs/template
div.row.mt-4.mx-4
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.number', 'number', '["required"]')(:loading="isLoadingGetInfoPhoto")
  div.col-sm-12.col-md-6.col-lg-4
    +date-picker-validation('body.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]')(
      :loading="isLoadingGetInfoPhoto"
      :max="new Date().toISOString()"
      min="1900-01-01"
      :locale="lang")
  div.col-sm-12.col-md-6.col-lg-4
    +date-picker-validation('body.date_end', 'dateTermination', 'dateTerminationObject', '["required", "minValue", "maxValue"]')(
      :loading="isLoadingGetInfoPhoto"
      max="2200-12-31"
      :min="body.date_start || new Date().toISOString()"
      :locale="lang")
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.last_name_ukr','lastNameUK','["required", "alphaUA"]')
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.first_name_ukr','firstNameUK','["required", "alphaUA"]')
  div.col-sm-12.col-md-6.col-lg-4
    +field-validation('body.middle_name_ukr','middleNameUK','["alphaUA"]')
  div.col-sm-12.col-md-6.col-lg-6
    +field-validation('body.last_name_eng','lastNameEN','["required", "alphaEN"]')(:loading="isLoadingGetInfoPhoto")
  div.col-sm-12.col-md-6.col-lg-6
    +field-validation('body.first_name_eng','firstNameEN','["required", "alphaEN"]')(:loading="isLoadingGetInfoPhoto")
  div.col-12
    +select('body.country','listCountries','country','valueName')
  div.col-12(v-if="checkAccess('sailorInternationalPassport', 'add_file')")
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(@click="checkFieldsValidation" :loading="isLoading" color="success") {{$t('save')}}
</template>

<script>
import { required, helpers, minValue, maxValue } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import { postFiles, getFileAll, ucFirst } from '@/mixins/main'
import FileDropZone from '@/components/atoms/DropZone/DropZone.vue'
import { checkAccess } from '@/mixins/permissions'
import { STATUSES_DOCUMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

const alphaUA = helpers.regex('alpha', /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'\- ]*$/)
const alphaEN = helpers.regex('alpha', /^[a-zA-Z'\- ]*$/)

const initBody = () => {
  return {
    number: null,
    date_start: null,
    date_end: null,
    last_name_ukr: null,
    first_name_ukr: null,
    middle_name_ukr: '',
    last_name_eng: null,
    first_name_eng: null,
    country: null
  }
}

export default {
  name: 'SailorInternationalPassportAdd',
  components: {
    FileDropZone
  },
  props: {
    passport: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody(),
      isLoading: false,
      files: [],
      postFiles,
      photoArray: [],
      isLoadingGetInfoPhoto: false,
      checkAccess
    }
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      valueName: state => state.main.lang === 'en' ? 'value_eng' : 'value',
      id: state => state.sailor.sailorId,
      sailorInfo: state => state.sailor.sailorInfo,
      listCountries: state => state.directory.country
    }),
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateTerminationObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    }
  },
  validations () {
    return {
      body: {
        number: { required },
        last_name_ukr: { required, alphaUA },
        first_name_ukr: { required, alphaUA },
        middle_name_ukr: { alphaUA },
        last_name_eng: { required, alphaEN },
        first_name_eng: { required, alphaEN }
      },
      dateTerminationObject: {
        required,
        minValue: minValue(this.dateStartObject || new Date()),
        maxValue: maxValue(new Date('2200-12-31'))
      },
      dateStartObject: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      }
    }
  },
  beforeMount () {
    if (this.$route?.params?.documentID) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.passport[key]))
    }
  },
  async mounted () {
    this.$route?.params?.documentID && this.checkForPhoto()
  },
  methods: {
    ...mapActions(['setSailorForeignPassports', 'updateSailorForeignPassportById', 'getInfoCheckForPhoto']),

    async checkForPhoto () {
      if (this.sailorDocument) {
        this.isLoadingGetInfoPhoto = true
        this.photoArray = await getFileAll(this.sailorDocument.photo)
        if (this.photoArray.length && this.photoArray[0] !== undefined) {
          const response = await this.getInfoCheckForPhoto(this.photoArray)
          for (let key in response) {
            this.body[`${key}_eng`] = await ucFirst(response[key]?.toLowerCase())
            key === 'serial_number' && (this.body.number = response[key])
            key === 'date_end' && (this.body[key] = response[key])
          }
          this.isLoadingGetInfoPhoto = false
        }
      }
      this.isLoadingGetInfoPhoto = false
    },

    checkFieldsValidation () {
      if (this.$v.$invalid) this.$v.$touch()
      else this.saveInternationalPassport()
    },

    async saveInternationalPassport () {
      const { documentID } = this.$route.params
      if (!documentID) this.body.status_document = STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.FOREIGN.DRAFT
      let data = {
        ...this.$route.params,
        body: this.body,
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'foreign_passport'
        }
      }
      this.isLoading = true
      const response = await this[!documentID ? 'setSailorForeignPassports' : 'updateSailorForeignPassportById'](data)
      if (SUCCESS_CODE.includes(response.code)) {
        if (!documentID) { // If called adding component
          this.$parent.isViewAddSlot = false
          this.body = initBody()
          this.$v.$reset()
        }
        if (this.$route.query?.viewEditBlock) this.$router.replace({ query: {} })
      }

      this.isLoading = false
    }
  }
}

</script>
